// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --white: #ffffff; 
    --principale: #D5227B;
    --blu: #43b6e2;
    --secondario: #18253C;
    --grigioScuro: #282c34;
    --link: #61dafb;

    --mobile-breakpoint: 768px;
  }


/*Font*/
body, h1, h2, h3, h4, p, a, span, button, input {
  font-family: 'Outfit' !important;
}

h1 {
  font-size: 32px;
  font-weight: 700;
}
h2 {
  font-size: 24px;
  font-weight: 700;
}
h3 {
  font-size: 20px;
  font-weight: 600;
}
h4 {
  font-size: 18px;
  font-weight: 600;
}
p {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/style/Colori-Typography.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,cAAc;IACd,qBAAqB;IACrB,sBAAsB;IACtB,eAAe;;IAEf,0BAA0B;EAC5B;;;AAGF,OAAO;AACP;EACE,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB","sourcesContent":[":root {\n    --white: #ffffff; \n    --principale: #D5227B;\n    --blu: #43b6e2;\n    --secondario: #18253C;\n    --grigioScuro: #282c34;\n    --link: #61dafb;\n\n    --mobile-breakpoint: 768px;\n  }\n\n\n/*Font*/\nbody, h1, h2, h3, h4, p, a, span, button, input {\n  font-family: 'Outfit' !important;\n}\n\nh1 {\n  font-size: 32px;\n  font-weight: 700;\n}\nh2 {\n  font-size: 24px;\n  font-weight: 700;\n}\nh3 {\n  font-size: 20px;\n  font-weight: 600;\n}\nh4 {\n  font-size: 18px;\n  font-weight: 600;\n}\np {\n  font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
