import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Typography, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import LoadingView from "../generics/LoadingView";
import { cloneDeep, set } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import '../../style/styleComponents/DialogAbbonamento.css';
import { Abbonamento, Mesi } from "../../models/Abbonamento";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/it';
import ApiAbbonamento from "../../api/Abbonamenti";



interface ComponentProps {
    open?: boolean;
    onClose?(open:boolean): void;
    onSave?(open:boolean): void;
    abbonamento?: Mesi;
    abbonamentoUtente?: Abbonamento;
    idUtente?: string;
}
export type Props = ComponentProps;


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  
  export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }
  

function BootstrapDialogTitle(props: DialogTitleProps) {
const { children, onClose, ...other } = props;


return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    {children}
    {onClose ? (
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
        </IconButton>
    ) : null}
    </DialogTitle>
);
}


function DialogAbbonamento(props: Props) {

    const [loading, setLoading] = useState(false);
    const [abbonamentoSelezionato, setAbbonamentoSelezionato] = useState<Mesi>();
    const [editMode, setEditMode] = useState(false);
    
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.locale('it');

    const oggi = dayjs(new Date()).add(1, 'day');

    const handleChangeSaldo = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const serv = cloneDeep(abbonamentoSelezionato);
        if(serv) {
            let value : any = event.target.value;
            value = parseFloat(value);

            set(serv, field, value);
            setAbbonamentoSelezionato(serv);
        }
    };

    const changeDataStart = (value: Dayjs | null) => {
        
        if(value) {
            const serv = cloneDeep(abbonamentoSelezionato);
            if(serv) {
                set(serv, 'dataInizio', value);

                const dataF = value.add(1, 'month');
                set(serv, 'dataFine', dataF);

                setAbbonamentoSelezionato(serv);
            }
        }
        
    }

    
    const handleClose = () => {
        setAbbonamentoSelezionato(undefined);
        props.onClose!(false);
    };

    const handleEliminaButton = async () => {
        if (window.confirm("Vuoi davvero eliminare un mese?")) {
            setLoading(true);
            if(abbonamentoSelezionato) {
                if(editMode) {
                    try {
                        const abb = cloneDeep(props.abbonamentoUtente);
                        if(abb) {
                            let indice = abb.mesi!.findIndex(obj => obj._id === abbonamentoSelezionato._id);
                            if (indice !== -1) {
                                abb.mesi!.splice(indice, 1);
                            }
                            await ApiAbbonamento.updateAbbonamento(abb);
                        }
                        toast.success('Abbonamento aggiornato con successo!');
                    } catch(err: any) {
                        toast.error(err);
                    }
                }
            }
            setAbbonamentoSelezionato(undefined);
            props.onSave!(false);
            setLoading(false);
        }
    }


    const handleSalvaButton = async () => {
        setLoading(true);
        if(abbonamentoSelezionato) {
            if(editMode) {
                try {
                    const abb = cloneDeep(props.abbonamentoUtente);
                    if(abb) {
                        let indice = abb.mesi!.findIndex(obj => obj._id === abbonamentoSelezionato._id);
                        if (indice !== -1) {
                            abb.mesi![indice] = abbonamentoSelezionato;
                        }
                        await ApiAbbonamento.updateAbbonamento(abb);
                    }
                    toast.success('Abbonamento aggiornato con successo!');
                } catch(err: any) {
                    toast.error(err);
                }
            } else {
                try {
                    const abb = cloneDeep(props.abbonamentoUtente);
                    if(abb) {
                        abb.mesi!.push(abbonamentoSelezionato);
                        await ApiAbbonamento.updateAbbonamento(abb);
                    }
                    else {
                        await ApiAbbonamento.addAbbonamento(
                            {
                                idUtente: props.idUtente,
                                mesi: [
                                    abbonamentoSelezionato
                                ]
                            }
                        );
                    }
                    toast.success('Abbonamento aggiunto con successo!');
                } catch(err: any) {
                    toast.error(err);
                }
            }
        }
        setAbbonamentoSelezionato(undefined);
        props.onSave!(false);
        setLoading(false);
    }

    useEffect(() => {
        if(!abbonamentoSelezionato) {
            if(!props.abbonamento) {
                setAbbonamentoSelezionato({dataInizio: "",
                                        dataFine: "",
                                        saldoRimanente: 100});
                setEditMode(false);
            } else {
                setAbbonamentoSelezionato(props.abbonamento);
                setEditMode(true);
            }
        }
    })

    useEffect(() => {
        if(props.abbonamento) {
            setAbbonamentoSelezionato(props.abbonamento);
            setEditMode(true);
        }
    }, [props.abbonamento])

    return (
        <div>
        <BootstrapDialog
            className="dialog-abbonamento"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={props.open!}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Configura l'abbonamento
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <div className="riga">
                    <FormControl className='form-field' variant="outlined" hidden={!editMode}>
                        <FormHelperText>ID</FormHelperText>
                        
                        <OutlinedInput
                            disabled
                            value={abbonamentoSelezionato?._id || ''}
                            />
                    </FormControl>
                </div>
                <div className="riga">
                    <FormControl className='form-field' variant="outlined">
                        <FormHelperText>Data Inizio</FormHelperText>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
                            <DatePicker  
                                value={dayjs(abbonamentoSelezionato?.dataInizio).locale('it')} 
                                onChange={(newValue: Dayjs | null) => changeDataStart(newValue)}
                            />
                        </LocalizationProvider>
                    </FormControl>
                </div>
                <div className="riga">
                    <FormControl className='form-field' variant="outlined">
                        <FormHelperText>Data Fine</FormHelperText>
                        <OutlinedInput
                            disabled
                            value={dayjs(abbonamentoSelezionato?.dataFine).locale('it').format('DD/MM/YYYY') || ""}
                        />
                    </FormControl>
                </div>
                <div className="riga">
                        <FormControl className='form-field' variant="outlined">
                            <FormHelperText>Saldo</FormHelperText>
                            <OutlinedInput
                                value={abbonamentoSelezionato?.saldoRimanente || ""}
                                onChange={handleChangeSaldo('saldoRimanente')} 
                            />
                        </FormControl>
                </div>
                
            </DialogContent>
            <DialogActions>
                <Button hidden={!editMode} style={{color: 'red'}} onClick={handleEliminaButton}>Elimina</Button>
                <Button onClick={handleSalvaButton}>Salva</Button>
            </DialogActions>
        </BootstrapDialog>

        <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />

        <LoadingView
                visible={loading}
        />

        </div>
    );
}
export default DialogAbbonamento;