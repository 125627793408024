import '../style/styleComponents/SidebarLeft.css';




function SidebarLeft() {
 



  return (
    <div>
     
     <div>
        <div className="riga"><a href="https://xenua.doagency.it/wp-content/uploads/2023/08/csv-esempio-input.csv">Scarica il file CSV di esempio</a></div>
        <div className="riga"><a target="blank" href="https://xenua.doagency.it/#contatti">Se hai problemi contattaci</a></div>
      </div>

    </div>
  );
}
export default SidebarLeft;