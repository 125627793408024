import { useEffect, useState } from "react";
import Auth from "../api/Auth";
import LoadingView from "../components/generics/LoadingView";

function Verify() {
    const [loading, setLoading] = useState(true);
    const [verified, setVerified] = useState(false);

    const verify = async (id: string | null) => {
        if(id !== null)
            await Auth.Verify(id);  

        setVerified(true);
        let back = "/login";
        window.location.replace(back);
    }

    useEffect(() => {
        if(!verified) {
            const queryParameters = new URLSearchParams(window.location.search);
            const id = queryParameters.get("id")
            verify(id);
        }
    });

    return (            
        <LoadingView visible={loading} />
    );
}

export default Verify;