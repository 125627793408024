

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import TextField from '@mui/material/TextField/TextField';
import React, { useState } from 'react';
import PasswordField from '../generics/PasswordField';
import '../../style/styleComponents/auth/SigninForm.css';


interface LoginProps {
  onLogin: (email: string, password: string, nome: string, cognome: string, telefono: string, azienda: string, checkboxprivacy: boolean) => void;
}

const LoginForm: React.FC<LoginProps> = ({ onLogin }) => {
  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [telefono, setTelefono] = useState('');
  const [azienda, setAzienda] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checkboxPrivacy, setCheckboxPrivacy] = useState(false);
  const [checkboxCommerciale, setCheckboxCommerciale] = useState(false);
  const [invioEseguito, setInvioEseguito] = useState(false);


  const handleCheckboxCommerciale = () => {
    setCheckboxCommerciale(!checkboxCommerciale);
  };
  const handleCheckboxPrivacy = () => {
    setCheckboxPrivacy(!checkboxPrivacy);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onLogin(email, password, nome, cognome, telefono, azienda, checkboxPrivacy);
    setInvioEseguito(true);
  };

  return (
    <>
      <h1>Registrati</h1>

      <form onSubmit={handleSubmit} className='signup-form'>
        <p>Dopo esserti registrato sarai contattato da un membro del nostro team per ricevere tutte le informazioni.</p>
        <div className="blocco-due-colonne">
          <FormControl variant="outlined">
            <FormHelperText>Email*</FormHelperText>
            <TextField
              type="email"
              className={"campo-form"}
              required
              error={!email && invioEseguito}
              value={email || ""}
              onChange={e => setEmail(e.target.value)}
            />
          </FormControl>

          <FormControl variant="outlined">
            <FormHelperText>Password*</FormHelperText>
            <PasswordField
              id="outlined-password-input"
              required
              onChange={setPassword} />
          </FormControl>
        </div>

        <div className="blocco-due-colonne">
          <FormControl variant="outlined">
            <FormHelperText>Nome*</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!nome && invioEseguito}
              value={nome || ""}
              onChange={e => setNome(e.target.value)}
            />
          </FormControl>
          <FormControl variant="outlined">
            <FormHelperText>Cognome*</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!cognome && invioEseguito}
              value={cognome || ""}
              onChange={e => setCognome(e.target.value)}
            />
          </FormControl>
        </div>

        <div className="blocco-due-colonne">
          <FormControl variant="outlined">
            <FormHelperText>Telefono*</FormHelperText>
            <TextField
              className={"campo-form"}
              required
              error={!telefono && invioEseguito}
              value={telefono || ""}
              onChange={e => setTelefono(e.target.value)}
            />
          </FormControl>
          <FormControl variant="outlined">
            <FormHelperText>Azienda</FormHelperText>
            <TextField
              className={"campo-form"}
              error={!azienda && invioEseguito}
              value={azienda || ""}
              onChange={e => setAzienda(e.target.value)}
            />
          </FormControl>
        </div>


        {/*<div className="riga-form">
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                control={<Checkbox />}
                label="Accettazione facoltativa."
                labelPlacement="end"
                value={checkboxCommerciale}
                checked={checkboxCommerciale || false}
                onChange={handleCheckboxCommerciale}
              />
            </FormGroup>
          </FormControl>
        </div>*/}
        <div className="riga-form">
          <FormControl component="fieldset"
                        required
                        error={invioEseguito && !checkboxPrivacy}
          >
            <FormGroup aria-label="position" row>
              <FormControlLabel
                  control={<Checkbox />}
                  label={
                    <div>
                        *Dichiaro di aver letto e di accettare i &nbsp;
                        <a href="https://xenua.doagency.it/termini-e-condizioni" target="_blank">termini e condizioni</a> della piattaforma.
                    </div>
                  }
                  labelPlacement="end"
                  value={checkboxPrivacy}
                  required
                  checked={checkboxPrivacy || false}
                  onChange={handleCheckboxPrivacy}
              />
            </FormGroup>
          </FormControl>
        </div>


        <button type="submit" className='btn-standard'>Registrati</button>
      </form>
    </>

  );
};

export default LoginForm;
