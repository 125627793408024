import '../style/stylePages/Home.css';
import { useDropzone } from 'react-dropzone';
import { Popover } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import UploadFile from '../components/UploadFile';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Constants from '../utils/Constants';
import AbbonamentoApi from '../api/Abbonamenti';
import { Abbonamento, Mesi } from '../models/Abbonamento';
import LavoraDate from '../utils/LavoraDate';
import { Utente } from '../models/Utente';
import LoadingView from '../components/generics/LoadingView';



function Home() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [cookie, setCookie] = useState<String>();
  const [utente, setUtente] = useState<Utente>();
  const [infoAbbonamentoInCorso, setInfoAbbonamentoInCorso] = useState<Mesi>();


  useEffect(() => {
    if (!cookie) {
      let authCookie = Cookies.get(Constants.nomeCookie.nome!);
      setCookie(authCookie);
      if(authCookie) {
          let ck = JSON.parse(authCookie);
          if(ck) {
            var utenteM : Utente = { _id: ck.user._id, ruolo: ck.user.ruolo, nome: ck.user.nome, cognome: ck.user.cognome };
            setUtente(utenteM);
            return;
          }
      }
      
    }

    navigate(`/login`);
  }, []);


  useEffect(() => {
    if (!infoAbbonamentoInCorso) {
      AbbonamentoF();
    }
    
  }, [infoAbbonamentoInCorso]);
 




  const AbbonamentoF = async () => {
    setLoading(true);
    try {
      const responseinfoAbbonamentoNow = await AbbonamentoApi.InfoAbbonamentoInCorso();
      if (responseinfoAbbonamentoNow.response && responseinfoAbbonamentoNow.response.status && responseinfoAbbonamentoNow.response.status == 404) {
        let infoAbbonamentoInCorsoNow: Mesi = {
          saldoRimanente: -1,
          dataInizio: "Abbonamento Mancante",
          dataFine: "2023-09-25T00:00:00",
        };
        setInfoAbbonamentoInCorso(infoAbbonamentoInCorsoNow);
      }
      else  {
        if (responseinfoAbbonamentoNow) {
          setInfoAbbonamentoInCorso(responseinfoAbbonamentoNow.data);
        } 
      }

    }
    catch (error) {
      console.error("Errore durante la chiamata API:", error);
    }
    setLoading(false);

  }


  const onChangeinfoAbbonamentoInCorso = () => {
    AbbonamentoF();
  }


  return (
    <div className="page-template">
      <div className="main-content">
      
        <div className="header-testo">
          <h1>Genera le descrizioni dei prodotti in modo massivo tramite l'AI</h1>
        </div>

        <div className="info-utente">
          <p><b>{utente ? utente.nome : ""} {utente ? utente.cognome : ""}</b></p>
          <p>ID utente: <b>{utente ? utente._id : ""}</b></p>
        </div>

        {infoAbbonamentoInCorso && infoAbbonamentoInCorso.saldoRimanente! >= 0 ? (
          <div>
            <div className="saldo-rimanente">
              <p>
                Descrizioni ancora generabili questo mese (fino al <b>{infoAbbonamentoInCorso ? LavoraDate.lavoraDataConMese(new Date(infoAbbonamentoInCorso.dataFine!))  : "Attendere il caricamento"}</b>): <b>{infoAbbonamentoInCorso ? infoAbbonamentoInCorso.saldoRimanente!.toString() : "Attendere il caricamento"}</b>
              </p>
              <p>
                Scadenza abbonamento: <b>{infoAbbonamentoInCorso ? LavoraDate.lavoraDataConMese(new Date(infoAbbonamentoInCorso.dataFineAbbonamento!))  : "Attendere il caricamento"}</b>
              </p>
            </div>

            <div>
              <UploadFile infoAbbonamentoInCorso={infoAbbonamentoInCorso?.saldoRimanente} onChangeinfoAbbonamentoInCorso={onChangeinfoAbbonamentoInCorso} />
            </div>
          </div>
        )
        : "Attualmente non hai un abbonamento in corso"}


      </div>
      <LoadingView
          visible={loading}
      />
    </div>
  );
}

export default Home;