import { useEffect, useState } from "react";
import SigninForm from "../components/auth/SigninForm";
import LoadingView from "../components/generics/LoadingView";
import Auth from "../api/Auth";
import Cookies from "js-cookie";
import Constants from "../utils/Constants";
import '../style/stylePages/Signup.css';
import HexParser from "../utils/HexParser";
import { ToastContainer, toast } from "react-toastify";



function Signup() {
    const [loading, setLoading] = useState(true);
    const [signupVisible, setSignupVisible] = useState(true);
    const [cookieChecked, setCookieChecked] = useState(false);

    const signin = async (email: string, password: string, nome: string, cognome: string, telefono: string, azienda: string, checkboxPrivacy: boolean) => {
        setLoading(true);
            
        let data = await Auth.Signin(email, password, nome, cognome, telefono, azienda, checkboxPrivacy);
        if(data) {
            setSignupVisible(false);
        }
        else {
            toast.error("L'utente risulta già registrato.");
        }
        setLoading(false);
    }

    useEffect(() => {
        if(!cookieChecked) {
            let authCookie = Cookies.get(Constants.nomeCookie.nome!);
            if(authCookie) {
                let ck = JSON.parse(authCookie);
                if(ck) {
                    setCookieChecked(true);
                    const queryParameters = new URLSearchParams(window.location.search);
                    let back = queryParameters.get("callback");
                    if(back)
                        back = HexParser.hex_to_ascii(back);
                    else
                        back = "/";
                        window.location.replace(back);
                    return;
                }
            }
            setCookieChecked(true);
            setLoading(false);
        }
    })

    return (
        <>
            <div className="page-template">
                <div className="main-content">  
                    {signupVisible && <SigninForm onLogin={signin}/>}
                    {!signupVisible && 
                        <div>
                            <h2>Grazie per esserti registrato!</h2>
                            <p>Controlla la tua casella email. Ti abbiamo inviato un messaggio per confermare il tuo indirizzo.</p>
                            <p>Clicca sul link all'interno e potrai accedere alla web app.</p>
                        </div>}
                </div>
            </div>
            <LoadingView visible={loading}/>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
  }
  
  export default Signup;