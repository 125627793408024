import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './style/Index.css';
import './style/MUI.css';
import Layout from "./layouts/Layout";
import NoPage from "./pages/NoPage";
import Home from "./pages/Home";
import Downloads from "./pages/Downloads";
import Signup from './pages/Signup';
import Login from "./pages/Login";
import Logout from './pages/Logout';
import Verify from './pages/Verify';
import Utenti from './pages/admin/Utenti';
import UtenteInfo from './pages/admin/UtenteInfo';
import RecuperaPassword from './pages/RecuperaPassword';
import ResetPassword from './pages/ResetPassword';
import Impostazioni from './pages/Impostazioni';
import ContestoUtente from './pages/admin/ContestoUtente';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<NoPage />} />
            <Route path="downloads" element={<Downloads />} />
            <Route path="impostazioni" element={<Impostazioni />} />
            <Route path="contesto-utente" element={<ContestoUtente />} />
            <Route path="signup" element={<Signup />} />
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="verify" element={<Verify />} />
            <Route path="utenti" element={<Utenti />} />
            <Route path="utente" element={<UtenteInfo />} />
            <Route path="recupera" element={<RecuperaPassword />} />
            <Route path="reset" element={<ResetPassword />} />
          </Route>
        </Routes>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

