// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blocco-due-colonne>.MuiFormControl-root {
    width: 50%
}`, "",{"version":3,"sources":["webpack://./src/style/styleComponents/auth/SigninForm.css"],"names":[],"mappings":"AAAA;IACI;AACJ","sourcesContent":[".blocco-due-colonne>.MuiFormControl-root {\n    width: 50%\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
