import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Cookies from "js-cookie";

export default class Auth {

  static getToken = () => {
    let authCookie = Cookies.get(Constants.nomeCookie.nome!);
    if(authCookie) {
        let ck = JSON.parse(authCookie);
        if(ck)
          return ck.token;
    }
    throw new Error("Not logged in");
  }



  static async Signin(email: string, password: String, nome: string, cognome: string, telefono: string, azienda: string, checkboxPrivacy?: Boolean) {
    try {
        const response = await Axios.post(Constants.apiWebService.baseUrl + '/register', {
            "ruolo": "utente",
            "nome": nome,
            "cognome": cognome,
            "email": email,
            "password": password,
            "telefono": telefono,
            "azienda": azienda,
            "checkboxPrivacy": checkboxPrivacy
        });
        const token = response.data; // Access the token from the response data
        return token;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }



  static async Login(email: string, password: String, expiration: number) {
    try {
        const response = await Axios.post(Constants.apiWebService.baseUrl + '/login', {
            "email": email,
            "password": password,
            "expiration": expiration
        });   
        const token = response.data; // Access the token from the response data
        return token;
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async Verify(id: string) {
    try {
      await Axios.post(Constants.apiWebService.baseUrl + '/verify', {
        "id": id
    });
    } catch (err: any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async ForgotPassword(email: string) {
    try {
      await Axios.post(Constants.apiWebService.baseUrl + '/forgot', {
        "email": email
      });
    } catch (err: any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async ResetPassword(id: string, password: string) {
    try {
      await Axios.post(Constants.apiWebService.baseUrl + '/reset', {
        "id": id,
        "password": password
      });
    } catch (err: any) {
      Errors.erroreApi(err.message);  
    }
  }

  static async Logout() {
    try {
      const token = Auth.getToken();
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      return await Axios.post(Constants.apiWebService.baseUrl + '/logout', {}, config);
    }
    catch (err : any) {
      Errors.erroreApi(err.message);  
    }  
  }
}