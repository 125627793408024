// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 500px;
    margin-top: 20px;
    padding: 15px 15px 15px 0;
}

.blocco-due-colonne {
    display: flex;
    gap: 15px;
}

@media only screen and (max-width: 768px) {
    .blocco-due-colonne {
        flex-direction: column;
    }
}

.login-form .btn-standard {
    margin-top: 20px;
    width: 150px;
}

.testo {
    margin-top: 30px;
}

.riga-form .MuiFormControlLabel-asterisk {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/style/stylePages/Signup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".signup-form {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n    width: 500px;\n    margin-top: 20px;\n    padding: 15px 15px 15px 0;\n}\n\n.blocco-due-colonne {\n    display: flex;\n    gap: 15px;\n}\n\n@media only screen and (max-width: 768px) {\n    .blocco-due-colonne {\n        flex-direction: column;\n    }\n}\n\n.login-form .btn-standard {\n    margin-top: 20px;\n    width: 150px;\n}\n\n.testo {\n    margin-top: 30px;\n}\n\n.riga-form .MuiFormControlLabel-asterisk {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
