import '../style/stylePages/Downloads.css';
import Files from '../api/Files';
import React from 'react';
import { File } from '../models/File';
import LavoraDate from '../utils/LavoraDate';
import { useEffect, useRef, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Upload } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import LoadingView from '../components/generics/LoadingView';
import { useLocation, useNavigate } from 'react-router-dom';


function Downloads() {
  const [loading, setLoading] = useState(true);
  const [elencoFile, setElencoFile] = useState<File[]>();
  const [isAdmin, setIsAdmin] = useState(false);

  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search);
  const navigate = useNavigate();

  useEffect(() => {
    const id = queryParameters.get("id");
    if(id)
      setIsAdmin(true);
    if (!elencoFile) {
      Files.DownloadTuttiFile(id).then(
        res=>{
          setElencoFile(res?.data);
          setLoading(false);
        }
      );
    }
  });


  const scaricaFile = async (nomeBlobFile: string, nomeFileCaricato: string) => {
    if (nomeBlobFile) {
      const response = await Files.DownloadSingoloFile(nomeBlobFile)
      if (response) {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type})
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = nomeFileCaricato
        link.click()
      }

    }
  }



  const mostraStato = (stato: string) => {
    var coloreStato = "colore-stato-rosso";
    switch (stato) {
      case "Completato":
        coloreStato = "colore-stato-verde";
      break;
      case "In lavorazione":
        coloreStato = "colore-stato-giallo"
      break;
      case "Errore":
        coloreStato = "colore-stato-rosso"
      break;
    }
    return <div className={coloreStato}>{stato}</div>;
  }


  const eliminaFile = async (_id: string, nomeBlobFile: string) => {
    try {
      const response = await Files.EliminaFile(_id, nomeBlobFile);

      if (response === undefined) {
        throw new Error('Response è undefined');
      }

      toast.success("File eliminato.");
      setElencoFile(undefined);
    } catch (error) {
      toast.error("C'è stato un errore.");
    }
  }



  return (
    <div className="page-template">
      <div className="main-content download-page">
        <h1>Area Downloads</h1>
        <a onClick={() => navigate(-1)} style={{display: isAdmin? 'block' : 'none'}}>Indietro</a>
        <div>
          <p></p>
          <p>Refresha la pagina se continui a vedere lo stato "in lavorazione".</p>
        </div>

        <div className="tabella lista-downloads">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead className="head-tabella">
                        <TableRow>
                            <TableCell>Tipo di file</TableCell>
                            <TableCell>Id blob</TableCell>
                            <TableCell>Data</TableCell>
                            <TableCell>Stato</TableCell>
                            <TableCell>N. prodotti</TableCell>
                            <TableCell>N. prodotti elaborati</TableCell>
                            <TableCell>Nome file</TableCell>
                            <TableCell>Azione</TableCell>
                            <TableCell>Elimina</TableCell>
                        </TableRow>
              </TableHead>
              {elencoFile && elencoFile.length > 0 ? 
              <TableBody>
                        {elencoFile?.map((row: File) => {
                            const dataPrenotazione = LavoraDate.lavoraDataConMeseEOrario(row.dataCaricamento!)

                            return (
                                <TableRow
                                    hover
                                    key={row._id}
                                    className="table-row"
                                    sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
                                >
                                    <TableCell><div className="immagine"><img src="img/csv-icon.png" /></div></TableCell>
                                    <TableCell>{row.nomeBlobFile}</TableCell>
                                    <TableCell>{dataPrenotazione}</TableCell>
                                    <TableCell>{mostraStato(row.stato!)}</TableCell>
                                    <TableCell>{row.numeroRighe}</TableCell>
                                    <TableCell>{row.numeroRigheElaborate}</TableCell>
                                    <TableCell>{row.nomeFileCaricato}</TableCell>
                                    <TableCell>
                                      <button 
                                        disabled={row.stato != "Completato" && row.stato != "Completato con errori" ? true : false} 
                                        className="btn-standard" 
                                        onClick={()=>scaricaFile(row.nomeBlobFile!, row.nomeFileCaricato!)}
                                        >Scarica il file
                                      </button>
                                    </TableCell>
                                    <TableCell>
                                      <button 
                                        disabled={row.stato == "In lavorazione" ? true : false}
                                        className='btn-standard btn-rosso'
                                        onClick={()=>eliminaFile(row._id!, row.nomeBlobFile!)}
                                      >
                                        Elimina
                                      </button></TableCell>
                                </TableRow>
                            )
                        })}
              </TableBody>

            : "Nessun file"}

            </Table>
          </TableContainer>
        </div>




        <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        

      </div>
      <LoadingView
          visible={loading}
      />
    </div>
  );
}

export default Downloads;