
export default class LavoraDate {

    //Prende formato data e restituisce stringa "12 Maggio 2023" (da mostrare a frontend)
    static lavoraDataConMese (formatoData: Date) {
        const nomiMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
        const date = new Date(formatoData);
        const anno = date.getFullYear();
        const mese = nomiMesi[date.getMonth()];
        const giorno = date.getDate();
        const dataLavorata = giorno + ' ' + mese + ' ' +anno;
        return dataLavorata;
    }

    //Prende formato string ("2023-06-17T14:00:00") e restituisce stringa "17 Giugno 2023 - ore 14:00" (da mostrare a frontend)
    static lavoraDataConMeseEOrario (formatoData: string) {
        const nomiMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
        const date = new Date(formatoData);
        const anno = date.getFullYear();
        const mese = nomiMesi[date.getMonth()];
        const giorno = date.getDate();
        const ora = String(date.getHours()).padStart(2, "0");
        const minuti = String(date.getMinutes()).padStart(2, "0");
        const dataLavorata = giorno + ' ' + mese + ' ' + anno + ' - ' + ora + ':' + minuti;
        return dataLavorata;
    }


    //Prende formato data e restituisce stringa "2023-7-2" (formato che vogliono alcune api)
    static lavoraData (formatoData: Date) {
        const date = new Date(formatoData);
        const anno = date.getFullYear();
        const mese = String(date.getMonth()+1).padStart(2, "0") + 1;
        const giorno = String(date.getDate()).padStart(2, "0");
        const dataLavorata = anno + '-' + mese + '-' + giorno;
        return dataLavorata;
    }

    
    //Prende formato data e restituisce stringa "2023-07-02T17:00:00" (formato che vogliono alcune api)
    static lavoraDataPerApi (data: Date) {
        const dataFormattata = data.getFullYear() + '-' + String(data.getMonth()+1).padStart(2, "0") + '-' + String(data.getDate()).padStart(2, "0") + 'T' + String(data.getHours()).padStart(2, "0") + ':' + String(data.getMinutes()).padStart(2, "0") + ':' + String(data.getSeconds()).padStart(2, "0");
        return dataFormattata;
    }


    //Prende formato data e restituice stringa oraria "9:30"
    static prendeOrario (formatoData: Date) {
        const date = new Date(formatoData);
        const ora = date.getHours();
        const minuti = String(date.getMinutes()).padStart(2, "0");
        return ora + ':' + minuti;
    }


}