import { useEffect, useState } from "react";
import { Utente } from "../../models/Utente";
import { Button, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import ApiUtentiAdmin from '../../api/UtentiAdmin';
import Cookies from "js-cookie";
import { cloneDeep, set } from "lodash";
import '../../style/stylePages/admin/Utenti.css';
import 'react-toastify/dist/ReactToastify.css';
import { Abbonamento, Mesi } from "../../models/Abbonamento";
import dayjs from "dayjs";



interface ComponentProps {
    idUtente?: string;
    abbonamenti?: Mesi[];
    openDialogFunc(row: Mesi): void;
}
export type Props = ComponentProps;

function AbbonamentiUtente(props: Props) {
    
    return (
        <div>
            <TableContainer component={Paper} className="container-tabella">
                <Table sx={{ minWidth: 650 }} className="tabella-servizi" aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Data inizio</TableCell>
                        <TableCell>Data fine</TableCell>
                        <TableCell>Saldo rimanente</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {props.abbonamenti?.map((row: Mesi) => (
                        <TableRow
                            hover
                            key={row._id}
                            className="table-row"
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell onClick={() => props.openDialogFunc(row)}>{dayjs(row.dataInizio).locale('it').format('DD/MM/YYYY')}</TableCell>
                            <TableCell onClick={() => props.openDialogFunc(row)}>{dayjs(row.dataFine).locale('it').format('DD/MM/YYYY')}</TableCell>
                            <TableCell onClick={() => props.openDialogFunc(row)}>{row.saldoRimanente}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/*<Stack spacing={2} className='paginazione'>
                <Pagination count={totalPages} color="primary" page={page} onChange={handleChangePage}/>
            </Stack>*/}
        </div>

    );
}

export default AbbonamentiUtente;