// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.riga .MuiFormControl-root {
    width: 100%;
}

.riga-due-colonne {
    display: flex;
    width: 100%;
}

.riga-due-colonne .MuiFormControl-root {
    width: 50%;
}

.riga-due-colonne .MuiFormControl-root:first-child {
    margin-right: 15px;
}

.riga-messaggio p {
    font-size: x-small;
    margin-bottom: 0;
    margin-left: 14px;
    color: gray;
}

.colonna-orari {
    margin-top: 10px;
    display: flex;
    gap: 15px;
}

.colonna-orari input {
    width: 50% !important;
}`, "",{"version":3,"sources":["webpack://./src/style/styleComponents/DialogAbbonamento.css"],"names":[],"mappings":";AACA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,WAAW;AACf;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["\n.riga .MuiFormControl-root {\n    width: 100%;\n}\n\n.riga-due-colonne {\n    display: flex;\n    width: 100%;\n}\n\n.riga-due-colonne .MuiFormControl-root {\n    width: 50%;\n}\n\n.riga-due-colonne .MuiFormControl-root:first-child {\n    margin-right: 15px;\n}\n\n.riga-messaggio p {\n    font-size: x-small;\n    margin-bottom: 0;\n    margin-left: 14px;\n    color: gray;\n}\n\n.colonna-orari {\n    margin-top: 10px;\n    display: flex;\n    gap: 15px;\n}\n\n.colonna-orari input {\n    width: 50% !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
