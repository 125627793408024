import { ToastContainer } from "react-toastify";
import LoadingView from "../components/generics/LoadingView";
import { useState } from "react";

function Impostazioni() {
    const [loading, setLoading] = useState(false);

    return (
        <div className="page-template">
            <div className="main-content pagina-impostazioni">
                <h1>Impostazioni</h1>
                <h2>In costruzione</h2>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            <LoadingView
                visible={loading}
            />
        </div>
    );
}

export default Impostazioni;