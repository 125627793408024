// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 200px;
    margin-top: 20px;
}

.login-form .btn-standard {
    margin-top: 20px;
    width: 150px;
}

.testo {
    margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/style/stylePages/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".login-form {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n    width: 200px;\n    margin-top: 20px;\n}\n\n.login-form .btn-standard {\n    margin-top: 20px;\n    width: 150px;\n}\n\n.testo {\n    margin-top: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
