import * as React from 'react';
import '../../style/styleComponents/LoadingView.css';

export type Props = {
  visible: boolean;
};

class LoadingView extends React.Component<Props> {
  render() {
    const { visible} = this.props;

    if (!visible) {
      return null;
    }

    return (
      <div className="contenitore-gifloading">
        <img src="img/gif-loading.gif" />
      </div>
    );
  }
}

export default LoadingView;
