import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Cookies from "js-cookie";
import Auth from "./Auth";
import { Abbonamento } from "../models/Abbonamento";


export default class Abbonamenti {
  

  static async InfoAbbonamentoInCorso() {
    try {
        const token = Auth.getToken();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        const res = await Axios.get(Constants.apiWebService.baseUrl + "/abbonamenti/infoAbbonamentoInCorso", config);
        return res;
      }
      catch (err : any) {
        Errors.erroreApi(err.message);  
        return err;
      }  
  }

  static async getListaAbbonamenti(idUtente: string) {
    try {
        const token = Auth.getToken();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        const res = await Axios.get(Constants.apiWebService.baseUrl + "/abbonamenti/utente/" + idUtente, config);
        return res;
      }
      catch (err : any) {
        Errors.erroreApi(err.message);  
        return err;
      }  
  }

  static async addAbbonamento(abbonamento: Abbonamento) {
    const token = Auth.getToken();
        const config = {
          headers: { Authorization: `Bearer ${token}` }
    };
    try {
        const response = await Axios.post(Constants.apiWebService.baseUrl + '/abbonamenti', abbonamento, config);
        return response.data;
    }
    catch (err : any) {
        Errors.erroreApi(err.message);  
    }
  }

  static async updateAbbonamento(abbonamento: Abbonamento) {
    const token = Auth.getToken();
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    try {
        const response = await Axios.patch(Constants.apiWebService.baseUrl + '/abbonamenti/' + abbonamento._id, abbonamento, config);
        return response.data;
    }
    catch (err : any) {
        Errors.erroreApi(err.message);  
    }
  }



}

