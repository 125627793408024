const Constants = {
    apiWebService:{
      baseUrl: process.env.REACT_APP_BASEURL
    },
    domain: {
      url: process.env.REACT_APP_DOMAIN
    },
    frontend: {
      url: process.env.REACT_APP_FRONTEND
    },
    domain: {
      url: process.env.SITOWPURL
    },
    nomeCookie: {
      nome: process.env.REACT_APP_NOMECOOKIE
    }
  };
  
  export default Constants;