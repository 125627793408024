// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.utente-info .button-container {
    width: 300px;
}

.utente-info .not-verified {
    color: red;
    margin-bottom: 0;
}

.utente-info .green-label {
    font-weight: 700;
    color: green;
}

.utente-info .red-label {
    font-weight: 700;
    color: red;
}

.utente-info .flex-container-row {
    width: 100% !important;
    flex-direction: row;
}
@media only screen and (min-width: 768px) {
    .utente-info .flex-container-row {
        display: flex;
    }
}

.utente-info .flex-container-row b {
    width: 40% !important;
}

.utente-info .flex-container-row p {
    margin-bottom: 0;
}

.utente-info .flex-container {
    margin: 40px 0;
    display: flex;
}

.utente-info .flex-container > div {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.utente-info .button-container {
    margin-top: 50px;
    margin-bottom: 30px;
}

.utente-info a {
    color: blue !important;
    text-decoration: underline !important;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/style/stylePages/admin/UtenteInfo.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,qCAAqC;IACrC,eAAe;AACnB","sourcesContent":[".utente-info .button-container {\n    width: 300px;\n}\n\n.utente-info .not-verified {\n    color: red;\n    margin-bottom: 0;\n}\n\n.utente-info .green-label {\n    font-weight: 700;\n    color: green;\n}\n\n.utente-info .red-label {\n    font-weight: 700;\n    color: red;\n}\n\n.utente-info .flex-container-row {\n    width: 100% !important;\n    flex-direction: row;\n}\n@media only screen and (min-width: 768px) {\n    .utente-info .flex-container-row {\n        display: flex;\n    }\n}\n\n.utente-info .flex-container-row b {\n    width: 40% !important;\n}\n\n.utente-info .flex-container-row p {\n    margin-bottom: 0;\n}\n\n.utente-info .flex-container {\n    margin: 40px 0;\n    display: flex;\n}\n\n.utente-info .flex-container > div {\n    width: 40%;\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n}\n\n.utente-info .button-container {\n    margin-top: 50px;\n    margin-bottom: 30px;\n}\n\n.utente-info a {\n    color: blue !important;\n    text-decoration: underline !important;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
