import Axios from "axios"
import Constants from "../utils/Constants";
import Errors from "../utils/Errors";
import Auth from "./Auth";

export default class UtentiAdmin {

    static async getElencoUtenti(filtri?: any, page: number = 1, limit: number = 10) {
        try {
            const token = Auth.getToken();
            const config = {
              headers: { Authorization: `Bearer ${token}` }
            };

            let filters = "";
            let fields = "";

            if(filtri.idInfermiere || filtri.nome || filtri.cognome || filtri.email) {
              filters += "filter=";
              fields += "&fields=";
              if(filtri.idInfermiere) {
                  filters += `${filtri.idInfermiere},`;
                  fields += `_id,`;
              }
              if(filtri.nome) {
                filters += `${filtri.nome},`;
                fields += `nome,`;
              }
              if(filtri.cognome) {
                filters += `${filtri.cognome},`;
                fields += `cognome,`;
              }
              if(filtri.email) {
                filters += `${filtri.email},`;
                fields += `email,`;
              }
              filters = filters.replace(/,\s*$/, "");
              fields = fields.replace(/,\s*$/, "");

              fields += "&"
            }
            
            const response = await Axios.get(`${Constants.apiWebService.baseUrl}/utenti?${filters}${fields}page=${page}&limit=${limit}`, config);
            return response.data;
        }
        catch (err : any) {
            Errors.erroreApi(err.message);  
        }
    }

    static async getSingoloUtente(idUtente: string) {
        try {
            const token = Auth.getToken();
            const config = {
              headers: { Authorization: `Bearer ${token}` }
            };
            const response = await Axios.get(Constants.apiWebService.baseUrl + '/utenti/' + idUtente, config);
            return response.data;
        } 
        catch (err : any) {
            Errors.erroreApi(err.message);    
        }
    }

    static async autorizzaAccesso(email: string) {
      try {
          const token = Auth.getToken();
          const config = {
            headers: { Authorization: `Bearer ${token}` }
          };
          const response = await Axios.post(Constants.apiWebService.baseUrl + '/autorizza', {
              "email": email,
          }, config);
              
          return response.data;
  
      }
      catch (err : any) {
        Errors.erroreApi(err.message);  
      }
    }

    static async rimuoviAccesso(email: string) {
        try {
            const token = Auth.getToken();
            const config = {
              headers: { Authorization: `Bearer ${token}` }
            };
            const response = await Axios.post(Constants.apiWebService.baseUrl + '/rimuoviAutorizzazione', {
                "email": email,
            }, config);
                
            return response.data;
    
        }
        catch (err : any) {
          Errors.erroreApi(err.message);  
        }
      }
}