import { Outlet, Link, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../style/Colori-Typography.css';
import '../style/Layout.css';
import '../style/Menu.css';
import Cookies from "js-cookie";
import SidebarLeft from '../components/SidebarLeft';
import Constants from "../utils/Constants";


const buildLoginButton = () => {
  let authCookie = Cookies.get(Constants.nomeCookie.nome!);
  if(authCookie) {
      let ck = JSON.parse(authCookie);
      if(ck) {
          return (
            <>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href={'/downloads'}>Downloads</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href={'/impostazioni'}>Impostazioni</a>
              </li>
              {ck && ck.user && ck.user.ruolo && ck.user.ruolo === 'admin' && 
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/utenti">Utenti</a>
                </li> 
              }
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href={'/logout'}>Logout</a>
              </li>
            </>
          )
      }
  }
  return (
    <>      
      <li className="nav-item">
        <a className="nav-link active" aria-current="page" href={'/signup'}>Registrati</a>
       </li>
      <li className="nav-item">
        <a className="nav-link active" aria-current="page" href={'/login'}>Login</a>
      </li>
    </>
  )
}

const Layout = () => {

  

  return (
    <>
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
              <a className="navbar-brand" href={process.env.REACT_APP_SITOWPURL}><img className="logoMenu" src="img/logo-xenua-scuro.png" /></a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <button className="close-button" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">X</button>
                  
                  <div className="container-top-menu-mobile">
                    <div className="logo"><a className="navbar-brand" href={process.env.REACT_APP_SITOWPURL}><img className="logoMenu" src="img/logo-xenua-scuro.png" /></a></div>
                  </div>

                  <div className="vociMenu">
                    <li className="nav-item">
                      <a className="nav-link active" aria-current="page" href={'https://xenua.doagency.it'}>Home</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link active" aria-current="page" href={'/'}>Carica il csv</a>
                    </li>

                    {buildLoginButton()}

                  </div>
              </ul>
              </div>
          </div>
      </nav>


      {/*Contenuto delle pagine:*/}
      <div className="blocco-esterno">
        <div className="sidebar-left"><SidebarLeft/></div>
        <div className="contenuto-principale">
          <Outlet/>
        </div>
      </div>
      

    </>
  )
};

export default Layout;