// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blocco-esterno {
    display: flex;
}


.sidebar-left {
    background: var(--secondario);
    width: 30%;
    max-width: 300px;
    padding: 155px 20px 20px 20px;
    color: white;
}


.sidebar-left .riga {
    margin-bottom: 20px;
}
.sidebar-left .riga a {
    color: white;
    text-decoration: none;
}


.contenuto-principale {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/style/Layout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;;AAGA;IACI,6BAA6B;IAC7B,UAAU;IACV,gBAAgB;IAChB,6BAA6B;IAC7B,YAAY;AAChB;;;AAGA;IACI,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,qBAAqB;AACzB;;;AAGA;IACI,WAAW;AACf","sourcesContent":[".blocco-esterno {\n    display: flex;\n}\n\n\n.sidebar-left {\n    background: var(--secondario);\n    width: 30%;\n    max-width: 300px;\n    padding: 155px 20px 20px 20px;\n    color: white;\n}\n\n\n.sidebar-left .riga {\n    margin-bottom: 20px;\n}\n.sidebar-left .riga a {\n    color: white;\n    text-decoration: none;\n}\n\n\n.contenuto-principale {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
