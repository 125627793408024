// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagina-impostazioni .form-field {
    margin: 20px 0;
}

.pagina-impostazioni .descrizione-campo {
    margin-left: 14px;
    font-size: 13px;
    font-style: italic;
    width: 580px;
    margin-bottom: 10px;
}

.pagina-impostazioni a {
    color: blue !important;
    text-decoration: underline !important;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/style/stylePages/Impostazioni.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,qCAAqC;IACrC,eAAe;AACnB","sourcesContent":[".pagina-impostazioni .form-field {\n    margin: 20px 0;\n}\n\n.pagina-impostazioni .descrizione-campo {\n    margin-left: 14px;\n    font-size: 13px;\n    font-style: italic;\n    width: 580px;\n    margin-bottom: 10px;\n}\n\n.pagina-impostazioni a {\n    color: blue !important;\n    text-decoration: underline !important;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
