// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contenitore-gifloading {
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: #ffffffd9;
    position: absolute;
    top: 0%;
    left: 0%;
    text-align: center;
}
.contenitore-gifloading img {
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 70px;
}`, "",{"version":3,"sources":["webpack://./src/style/styleComponents/LoadingView.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,+BAA+B;IAC/B,WAAW;AACf","sourcesContent":[".contenitore-gifloading {\n    z-index: 9999;\n    width: 100%;\n    height: 100%;\n    background: #ffffffd9;\n    position: absolute;\n    top: 0%;\n    left: 0%;\n    text-align: center;\n}\n.contenitore-gifloading img {\n    position: absolute;\n    top: 50%;\n    transform: translate(-50%,-50%);\n    width: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
