// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDialog-paper {
    min-width: 300px;
    min-height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/style/MUI.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".MuiDialog-paper {\n    min-width: 300px;\n    min-height: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
