// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-row {
    cursor: pointer;
}

.green-label {
    font-weight: 700;
    color: green;
}

.red-label {
    font-weight: 700;
    color: red;
}

.filtri {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.paginazione {
    margin-top: 15px;
}

.container-tabella {
    max-height: 375px;
}`, "",{"version":3,"sources":["webpack://./src/style/stylePages/admin/Utenti.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".table-row {\n    cursor: pointer;\n}\n\n.green-label {\n    font-weight: 700;\n    color: green;\n}\n\n.red-label {\n    font-weight: 700;\n    color: red;\n}\n\n.filtri {\n    display: flex;\n    gap: 15px;\n    margin-bottom: 15px;\n}\n\n.paginazione {\n    margin-top: 15px;\n}\n\n.container-tabella {\n    max-height: 375px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
