import '../../style/stylePages/Impostazioni.css';
import { useEffect, useState } from "react";
import { ImpostazioniUtente } from "../../models/ImpostazioniUtente";
import ApiImpostazioniUtenti from '../../api/ImpostazioniUtenti';
import Cookies from "js-cookie";
import Constants from "../../utils/Constants";
import { cloneDeep } from "lodash";
import { Button, FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import LoadingView from "../../components/generics/LoadingView";
import { useLocation, useNavigate } from 'react-router-dom';

function ContestoUtente() {
    const [loading, setLoading] = useState(false);
    const [impostazioniUtente, setImpostazioniUtente] = useState<ImpostazioniUtente>();
    const [idUtente, setIdUtente] = useState<String>();
    const [editMode, setEditMode] = useState<boolean>(true);

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search);
    const navigate = useNavigate();
    
    const getImpostazioni = async (idUtente: string | null) => {
        if(idUtente !== null) {
            setLoading(true);
            let imp: ImpostazioniUtente = await ApiImpostazioniUtenti.getImpostazioniUtente(idUtente);
            if(!imp) {
                setEditMode(false);
                imp = {
                    idUtente: idUtente,
                    contesto: ""
                }
            }
            setImpostazioniUtente(imp);
            setLoading(false);
        }
    }

    const handleChange = async(event: React.ChangeEvent<HTMLInputElement>) => {
        const imp = cloneDeep(impostazioniUtente);
        imp!.contesto = event.target.value;
        setImpostazioniUtente(imp);
    }

    const handleSalvaButton = async () => {
        setLoading(true);
        if(impostazioniUtente) {
            if(editMode) {
                try {
                    await ApiImpostazioniUtenti.updateImpostazioniUtente(impostazioniUtente);
                    toast.success('Impostazioni aggiornate con successo!');
                } catch(err: any) {
                    toast.error(err);
                }
            } else {
                try {
                    await ApiImpostazioniUtenti.addImpostazioniUtente(impostazioniUtente);
                    toast.success('Impostazioni aggiunte con successo!');
                } catch(err: any) {
                    toast.error(err);
                }
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        const id = queryParameters.get("id");

        if(id && !idUtente) {
            setIdUtente(id)
            getImpostazioni(id);
        }
    }, []);
        
    return (
        <div className="page-template">
            <div className="main-content pagina-impostazioni">
                <h1>Contesto Utente</h1>
                <a onClick={() => navigate(`/utente?id=${idUtente}`)}>Indietro</a>
                <div>
                    <FormControl className='form-field' variant="outlined">
                        <FormHelperText>Contesto</FormHelperText>
                        <label className="descrizione-campo">Nel contesto è possibile inserire ulteriori informazioni per migliorare la qualità dell'output. Un esempio: "L'ecommerce per cui stai creando
                            le informazioni è uno shop di moda. Il tono delle risposte deve essere molto professionale. Non rivolgerti mai con il tu all'utente."
                        </label>
                        <OutlinedInput
                            multiline
                            rows={6}
                            value={impostazioniUtente?.contesto || ''}
                            onChange={handleChange}
                            style={{width: 600}}
                        />
                    </FormControl>
                </div>
                <button onClick={handleSalvaButton} className="btn-standard">Salva</button>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />

            <LoadingView
                visible={loading}
            />
        </div>
    );
}

export default ContestoUtente;